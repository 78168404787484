import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'formik';
import FormLabel from '../FormLabel/FormLabel';
import {standardDefaultPropsForFields, standardPropTypesForFields} from '../constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {renderInputField, renderTextareaField} from './renderFields';

export const Input = ({label, name, validate, isTextarea}) => (
	<>
		<FormLabel text={label} />
		<Field name={name} validate={validate}>
			{isTextarea ? renderTextareaField : renderInputField}
		</Field>
		<ErrorMessage name={name} />
	</>
);

Input.propTypes = {
	...standardPropTypesForFields,
	isTextarea: PropTypes.bool,
};
Input.defaultProps = {
	...standardDefaultPropsForFields,
	isTextarea: false,
};

export const Textarea = (props) => <Input {...props} isTextarea />;
