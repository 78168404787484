import React from 'react';
import PropTypes from 'prop-types';
import StateHandler from './StateHandler';

// this complete module Elements/Dropdown was taken from project "medweiser"

import ListItem from './ListItem/ListItem';
import EmptyListItem from './ListItem/EmptyListItem';

import {DropdownBox, DropdownHeader, ListWrapper, HeaderText, DropDownContainer, InfoIcon, ArrowIcon} from './styles';

const emptyValue = {}; // no type, no label

const DropdownElement = props => {
	const {
		items,
		defaultHeader,
		hasError,
		onBlur,
		onChange,
		mulipleSelect,
		enableIcons,
		selectedItems,
		placeholder
	} = props;
	return (
		<StateHandler
			render={thiz => {
				const {state} = thiz;
				const headerText = items
					.filter(item => selectedItems.includes(item.type))
					.map(item => item.label)
					.join(', ');
				return (
					<DropDownContainer>
						<DropdownBox onClick={thiz.toggleList}>
							<DropdownHeader placeHolder={!selectedItems.length}>
								<InfoIcon name="circle_info_mark" />
								<HeaderText
									hasError={hasError}
									onBlur={onBlur}
									placeholder={placeholder}
									value={headerText || defaultHeader}
								/>
								<ArrowIcon />
							</DropdownHeader>
							{state.listOpen && (
								<ListWrapper>
									<EmptyListItem label={placeholder} onClick={() => onChange(emptyValue)} />
									{props.items.map((item, index) => {
										return (
											<ListItem
												iconName={enableIcons ? item.type : ''}
												enableCheckbox={mulipleSelect}
												onClick={() => {
													onChange(props.items[index]);
												}}
												checked={selectedItems.includes(item.type)}
												/* eslint-disable-next-line */
												key={index}>
												{item.label}
											</ListItem>
										);
									})}
								</ListWrapper>
							)}
						</DropdownBox>
					</DropDownContainer>
				);
			}}
		/>
	);
};

DropdownElement.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	defaultHeader: PropTypes.string,
	onChange: PropTypes.func,
	mulipleSelect: PropTypes.bool,
	enableIcons: PropTypes.bool,
	hasError: PropTypes.string,
	placeholder: PropTypes.string,
	onBlur: PropTypes.func,
	selectedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

DropdownElement.defaultProps = {
	onChange: () => {},
	onBlur: null,
	defaultHeader: '',
	hasError: '',
	placeholder: '',
	mulipleSelect: false,
	enableIcons: false,
	selectedItems: []
};

export default DropdownElement;
