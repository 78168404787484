import * as Yup from 'yup';

export const captchaValidation = (errorMessage) => Yup.string().nullable().trim().required(errorMessage);

export const emailValidation = (messageIfEmptyOrNone, messageIfNotValid) =>
	// messageIfEmptyOrNone is null --> not required
	messageIfEmptyOrNone
		? Yup.string().trim().required(messageIfEmptyOrNone).email(messageIfNotValid)
		: Yup.string().trim().email(messageIfNotValid);

export const nonEmptyStringValidation = (errorMessage) => Yup.string().trim().required(errorMessage);

export const dateValidation = (errorMessage) => Yup.date().required(errorMessage);

export const mustBeTrueValidation = (errorMessage) => Yup.boolean().oneOf([true], errorMessage);
