import styled from 'styled-components';
import {alertColors, colors, sizes} from '../../../../styles/variables';
import {Icon} from '../../Icon/Icon';
import {withUnit} from '../../../../styles/utils';

export const InfoIcon = styled(Icon)`
	transform: rotate(180deg);
	position: absolute;
`;

export const ArrowIcon = styled(Icon).attrs({name: 'chevron_right'})`
	color: ${colors.carrot};
	font-size: 1em;
	position: absolute;
	right: 1.3em;
	top: 50%;
	transform: translateY(-50%) rotate(90deg);
`;

export const DropDownContainer = styled.div`
	font-size: 1em;
	box-sizing: border-box;
`;

export const DropdownBox = styled.div`
	position: relative;
	background-color: ${colors.white};
	border-radius: 0.2em;
	font-size: 0.9em;
	cursor: pointer;
`;

// export const DropdownHeader = styled.div`
// 	display: flex;
// 	padding: 0.4em 15px;
// 	align-items: center;
// 	color: ${props => (props.placeHolder ? colors.spacegray : colors.mint)};
// `;

export const DropdownHeader = styled.div``;

export const DropdownTitle = styled.div`
	font-size: 0.7em;
	color: ${colors.midnight_match};
	font-weight: bold;
`;

export const HeaderText = styled.input.attrs({readOnly: true})`
	box-sizing: border-box;
	width: 100%;
	height: ${sizes.forms.inputHeight};
	border-radius: ${sizes.borderRadius};
	background-color: ${colors.snow};
	border-width: 2px;
	border-style: solid;
	border-color: ${props => (props.hasError ? alertColors.error.full : `${colors.snow}`)};
	outline: none;
	padding: 0 ${withUnit(sizes.distance.base * 1.5)} 0 ${withUnit(sizes.distance.base)};
	cursor: pointer;
	text-overflow: ellipsis;

	&:focus {
		border: 2px solid ${alertColors.focus.full};
		& + span {
			color: ${alertColors.focus.full};
		}
	}
`;

// because the element is absolutely positioned, we reset z-index
// with 'zindex.dropdownListWrapper', so that the dropdown list
// is not overlapped by other elements
export const ListWrapper = styled.ul`
	box-sizing: border-box;
	overflow: auto;
	position: absolute;
	top: calc(100% + 10px);
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 1em);
	padding: 1em;
	z-index: 100;
	margin-top: -0.3em;
	max-height: 30em;
	background-color: ${colors.white};
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	text-overflow: ellipsis;

	& > li {
		min-height: 1em;

		&:hover {
			color: ${colors.carrot};
			box-shadow: none;
		}
	}
`;
