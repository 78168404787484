import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import DropdownElement from './DropdownElement';
import {hasError} from '../formUtils';

import FormLabel from '../FormLabel/FormLabel';
import {standardDefaultPropsForFields, standardPropTypesForFields} from '../constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const defaultPlaceholder = 'Bitte auswählen';

const Dropdown = ({label, items, name, validate}) => (
	<>
		<FormLabel text={label} required={validate && validate.required} />
		<Field name={name} validate={validate}>
			{(formikProps) => {
				const {
					field: {value},
					form: {setFieldTouched, setFieldValue},
				} = formikProps;

				const propsForDropdown = {
					defaultHeader: '',
					hasError: hasError(formikProps),
					items,
					onChange: (newSelection) => {
						const newValue = newSelection.type; // can be: undefined
						if (newValue !== value) {
							setFieldValue(name, newValue); // because formikProps.field.onChange() doesn't work
							setFieldTouched(name); // always
						}
					},
					onBlur: () => setFieldTouched(name),
					placeholder: defaultPlaceholder,
					selectedItems: [value],
				};

				return <DropdownElement {...propsForDropdown} />;
			}}
		</Field>
		<ErrorMessage name={name} />
	</>
);

Dropdown.propTypes = {
	...standardPropTypesForFields,
	validate: PropTypes.func,
};

Dropdown.defaultProps = {
	...standardDefaultPropsForFields,
	validate: null,
};

export default Dropdown;
