import React from 'react';
import PropTypes from 'prop-types';
import {ListItemWrapper, ListIcon, Label} from './styles';

const Checkbox = props => <input type="checkbox" {...props} />;

const ListItem = props => {
	const {iconName, children, enableCheckbox, checked, readonly} = props;

	const handleItemClick = e => {
		if (enableCheckbox) {
			e.stopPropagation();
		}
		props.onClick();
	};
	const checkbox = enableCheckbox ? <Checkbox checked={checked} onClick={handleItemClick} /> : null;

	const listIcon = iconName !== '' ? <ListIcon name={iconName} /> : null;

	

	
	
	return (
		<ListItemWrapper readonly={readonly} checked={checked} onClick={handleItemClick}>
			{listIcon}
			<Label>{children}</Label>
			{checkbox}
		</ListItemWrapper>
	);
};

ListItem.defaultProps = {
	iconName: '',
	enableCheckbox: false,
	onClick: () => {},
	checked: false,
	readonly: false,
	children: undefined
};

ListItem.propTypes = {
	iconName: PropTypes.string,
	enableCheckbox: PropTypes.bool,
	onClick: PropTypes.func,
	checked: PropTypes.bool,
	readonly: PropTypes.bool,
	children: PropTypes.string
};

export default ListItem;
