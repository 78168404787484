import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../../../styles/variables';

// import Icon from '../../SelectionCriteria/Icon';
const Icon = props => <span {...props}>x</span>;

export const ListItemWrapper = styled.li`
	
	
	padding: 0.3em 0.5em 0.3em 0.2em;
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
	color: ${props => (props.readonly ? colors.lightGrey : props.checked ? colors.mint_match : colors.lightGrey)};
	@media (hover: hover) {
		&:hover {
			background-color: ${colors.eggshell_lighter};
			color: ${props => (props.readonly ? colors.lightGrey : colors.mint_match)};
			border-radius: 1em;
		}
	}
`;

export const Label = styled.div`
	flex: 1 0 auto;
	font-weight: bolder;
	width: 100%;
`;

export const ListIcon = styled(Icon)`
	width: 1.4em;
	font-size: 1.4em;
	height: 1.4em;
	margin-right: 0.5em;
`;

export const EmptyListItemStyled = styled(ListItemWrapper)`
	color: ${colors.charcoal};
	&:hover {
		color: inherit;
	}
`;
