import styled from 'styled-components';
import {colors, sizes} from '../../../styles/variables';
import {withUnit, gradientCandyCarrot, orangeBorder, media, gradientService} from '../../../styles/utils';
import {Icon} from '../Icon/Icon';
import {GlobalListStyle, GlobalOrderedListStyle} from '../../../styles/global';
import {HeadlineStyled} from '../Headline/styles';

export const themes = ['grey', 'colored', 'service'];

export const IconStyled = styled(Icon)`
	z-index: -1;
	position: absolute;
	padding: 0 ${withUnit(sizes.distance.base / 3)};
	transform: rotate(-7deg);

	width: 50vw;
	height: 50vw;
	bottom: -8vw;
	right: -8vw;

	${media.sm`
		width: 25vw;
		height: 25vw;
		bottom: -3vw;
		right: -3vw;
	`};

	${media.xl`
		width: 20vw;
		height: 20vw;
		bottom: -2vw;
		right: -5vw;
	`};

	svg {
		width: 50vw;
		height: 50vw;
		fill: ${colors.lightgrey};
		opacity: 0.5;

		${media.sm`
		width: 25vw;
		height: 25vw;
		`};

		${media.xl`
			width: 20vw;
			height: 20vw;
		`};
	}
`;

export const InfoboxStyled = styled.div`
	z-index: 1;
	position: relative;
	overflow: hidden;
	// width: 100%;
	display: flex;
	align-items: flex-start;
	padding: ${withUnit(sizes.distance.base / 2)};
	// padding-right: ${withUnit(sizes.distance.base * 4)};
	margin-bottom: ${withUnit(sizes.distance.base)};
	margin-right: 0;

	color: ${colors.white};
	font-size: 0.9em;
	line-height: 1.5;

	> div {
		> *:first-child {
			margin-top: 0;
		}
		
		${media.md`
			padding: ${withUnit(sizes.distance.base)};
		`};

		> *:last-child {
			margin-bottom: 0;
		}
	}
	
	a {
		color: ${colors.carrot};
		font-weight: 600;
		
		&:hover, 
		&:active, 
		&:focus {
			color: ${colors.candy};
		}
	}

	ul {
		${GlobalListStyle};
	}

	ol {
		${GlobalOrderedListStyle};
	}

	h2 {
		${HeadlineStyled};
		font-size: 1.2em;
		margin-top: ${withUnit(sizes.distance.base / 3)};
		margin-bottom: ${withUnit(sizes.distance.base / 2)};

		${media.md`
			font-size: 1.4em;
			margin-top: ${withUnit(sizes.distance.base / 2)};
			margin-bottom: ${withUnit(sizes.distance.base)};
		`};
	}

	h3 {
		position: relative;
		font-size: 1em;
		padding-bottom: ${withUnit(sizes.distance.base / 3)};
		margin-top: 0;
		margin-bottom: ${withUnit(sizes.distance.base / 3)};
		${orangeBorder()};

		${media.md`
			font-size: 1em;
			padding-bottom: ${withUnit(sizes.distance.base / 3)};
			margin-bottom: ${withUnit(sizes.distance.base / 2)};
		`};
	}

	h4 {
		font-size: 1em;
	}

	h5 {
		${HeadlineStyled};
		font-size: 1.1em;
		margin-top: 0;
		margin-bottom: ${withUnit(sizes.distance.base / 2)};
	}

	h6 {
		${HeadlineStyled};
		font-size: 1em;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: ${withUnit(sizes.distance.base / 2)};
	}


	${props =>
		props.theme === 'grey'
			? `
				background-color: ${colors.snow};
				color: ${colors.black}; 
				
				a, a:visited {
					position: relative;
					text-decoration: underline;
				}
				
				a:hover, a:active, a:focus {
				}
				
				ul li::before {
					background-color: ${colors.carrot};
				}
				`
			: props.theme === 'service'
			? `
				${gradientService()}
				
				ul li::before {
					background-color: ${colors.white};
				}
				
				a, a:visited {
					color: ${colors.white};
					position: relative;
					text-decoration: underline;
				}
				
				a:hover, a:active, a:focus {
					color: ${colors.snow};
				}

				svg {
					fill: white;
					opacity: 0.2;
				}

			`
			: `
				${gradientCandyCarrot()}
				
				ul li::before {
					background-color: ${colors.white};
				} 
				
				a, a:visited {
					color: ${colors.white};
					position: relative;
				}
				
				a:hover, a:active, a:focus {
					color: ${colors.snow};
				}

				svg {
					fill: white;
					opacity: 0.3;
				}
			`};
`;
