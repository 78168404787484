import React from 'react';
import PropTypes from 'prop-types';
import {InfoboxStyled, IconStyled, themes} from './styles';

const Infobox = props => {
	const {text, theme, icon, className} = props;
	let useIcon = icon
	if (theme === 'service') {
		useIcon = 'therapie';
	}
	const iconElement = icon ? <IconStyled type="teaser" name={useIcon} /> : null;
	return (
		<InfoboxStyled className={className} theme={theme}>
			{iconElement}
			<div dangerouslySetInnerHTML={{__html: text}} />
		</InfoboxStyled>
	);
};

Infobox.propTypes = {
	text: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(themes),
	icon: PropTypes.string,
	className: PropTypes.string
};

Infobox.defaultProps = {
	theme: 'grey',
	icon: 'info',
	className: 'ap-infobox'
};

/** @component */
export default Infobox;
