import React from 'react';
import PropTypes from 'prop-types';

import {EmptyListItemStyled} from './styles';

const EmptyListItem = ({label, onClick}) => <EmptyListItemStyled onClick={onClick}>{label}</EmptyListItemStyled>;

EmptyListItem.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};

export default EmptyListItem;
