import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

class StateHandler extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: false
		};
	}

	handleClickOutside = evt => {
		this.setState({
			listOpen: false
		});
	};

	toggleList = () => {
		this.setState(prevState => ({
			listOpen: !prevState.listOpen
		}));
	};

	render() {
		return this.props.render(this);
	}
}
export default onClickOutside(StateHandler);
